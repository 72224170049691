import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DangerousIcon from "@mui/icons-material/Dangerous";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BugReportIcon from "@mui/icons-material/BugReport";
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import * as React from "react";
import {blue, green, orange, yellow} from "@mui/material/colors";

export function getIcon(eventType: string | undefined | null) {
    if (eventType === "New") return <AutoAwesomeIcon/>;
    if (eventType === "Deadline") return <NotificationsActiveIcon/>;
    if (eventType === "Update") return <TipsAndUpdatesOutlinedIcon/>;
    if (eventType === "Reply") return <QuestionAnswerIcon/>;
    if (eventType === "Applied") return <CloudUploadIcon/>;
    if (eventType === "Rejected") return <DangerousIcon/>;
    if (eventType === "Assigned") return <HowToRegIcon/>;
    if (eventType === "Qualified") return <VerifiedRoundedIcon/>;
    return <BugReportIcon/>
}

export function getColor(eventType: string | undefined | null) {
    if (eventType === "New") return "success.light";
    if (eventType === "Deadline") return "warning.light";
    if (eventType === "Update") return "secondary.main";
    if (eventType === "Reply") return "secondary.light";
    if (eventType === "Applied") return "success.light";
    if (eventType === "Rejected") return "error.dark";
    if (eventType === "Assigned") return "success.main";
    if (eventType === "Qualified") return "success.light";
    return "error.dark";
}

export function getLink(url: string | null): string | undefined {
    return url ? url : undefined;
}

function makeFavIconUrl(domain: string) {
    return "https://s2.googleusercontent.com/s2/favicons?domain=" + domain
}

export function getBuyerIcon(buyer: string) {
    if (!buyer) return ""
    let buyerName = buyer.toUpperCase();
    if (buyerName === "7N NORGE AS") return makeFavIconUrl("7n.com")
    if (buyerName === "A-2 NORGE AS") return makeFavIconUrl("a-2.no")
    if (buyerName === "ABOVEIT AS") return makeFavIconUrl("aboveit.no")
    if (buyerName === "ACCELIA CONSULTING AS") return makeFavIconUrl("acceliaconsulting.no")
    if (buyerName === "ACCENTURE AS") return makeFavIconUrl("accenture.com")
    if (buyerName === "ACHILLES INFORMATION AS") return makeFavIconUrl("achilles.com")
    if (buyerName === "ADVANSIA AS") return makeFavIconUrl("advansia.no")
    if (buyerName === "ADVISETECH AS") return makeFavIconUrl("advisetech.no")
    if (buyerName === "AFRY NORWAY AS") return makeFavIconUrl("afry.com")
    if (buyerName === "AGENDA KAUPANG AS") return makeFavIconUrl("agendakaupang.no")
    if (buyerName === "AGENS AS") return makeFavIconUrl("agens.no")
    if (buyerName === "AGITEC AS") return makeFavIconUrl("")
    if (buyerName === "AKTIESELSKAPET VINMONOPOLET") return makeFavIconUrl("vinmonopolet.no")
    if (buyerName === "ALTRO AS") return makeFavIconUrl("")
    if (buyerName === "ALV AS") return makeFavIconUrl("alv.no")
    if (buyerName === "ANMASO AS") return makeFavIconUrl("")
    if (buyerName === "APEX INVENT AS") return makeFavIconUrl("")
    if (buyerName === "ARBEIDS- OG VELFERDSETATEN (NAV)") return makeFavIconUrl("nav.no")
    if (buyerName === "ARBEIDS- OG VELFERDSETATEN") return makeFavIconUrl("nav.no")
    if (buyerName === "ARTHUR D. LITTLE AS") return makeFavIconUrl("")
    if (buyerName === "ARVADUS AS") return makeFavIconUrl("")
    if (buyerName === "AS VINMONOPOLET") return makeFavIconUrl("vinmonopolet.no")
    if (buyerName === "ASPLAN VIAK AS") return makeFavIconUrl("")
    if (buyerName === "ATEA AS") return makeFavIconUrl("atea.no")
    if (buyerName === "AVINOR AS") return makeFavIconUrl("avinor.no")
    if (buyerName === "AVO CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "AXAZ AS") return makeFavIconUrl("")
    if (buyerName === "AZETS CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "BAIN & COMPANY NORWAY AS") return makeFavIconUrl("bain.com")
    if (buyerName === "BANE NOR SF") return makeFavIconUrl("banenor.no")
    if (buyerName === "BANE NOR SF") return makeFavIconUrl("banenor.no")
    if (buyerName === "BDO AS") return makeFavIconUrl("")
    if (buyerName === "BEARINGPOINT NORWAY AS") return makeFavIconUrl("")
    if (buyerName === "BEKK CONSULTING AS") return makeFavIconUrl("bekk.no")
    if (buyerName === "BETA MOBILITY CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "BINR AS") return makeFavIconUrl("")
    if (buyerName === "BLANK AS") return makeFavIconUrl("blank.no")
    if (buyerName === "BLINQ AS") return makeFavIconUrl("")
    if (buyerName === "BLUE TREE AS") return makeFavIconUrl("")
    if (buyerName === "BOITANO OSLO AS") return makeFavIconUrl("")
    if (buyerName === "BOUVET NORGE AS") return makeFavIconUrl("bouvet.no")
    if (buyerName === "BRAINBASE AS") return makeFavIconUrl("brainbase.no")
    if (buyerName === "BREDVID AS") return makeFavIconUrl("")
    if (buyerName === "BRIVO AS") return makeFavIconUrl("")
    if (buyerName === "BSPOKE AS") return makeFavIconUrl("")
    if (buyerName === "BT CONSULTING SERVICES AS") return makeFavIconUrl("")
    if (buyerName === "BUREAU 1618 AS") return makeFavIconUrl("")
    if (buyerName === "CANTERRA AS") return makeFavIconUrl("")
    if (buyerName === "CAPGEMINI NORGE AS") return makeFavIconUrl("capgemini.com")
    if (buyerName === "CAPRA CONSULTING AS") return makeFavIconUrl("capraconsulting.no")
    if (buyerName === "CGI NORGE AS") return makeFavIconUrl(".cgi.com")
    if (buyerName === "CLOUDBERRIES AS") return makeFavIconUrl("cloudberries.no")
    if (buyerName === "COMPUTAS AS") return makeFavIconUrl("computas.com")
    if (buyerName === "CONSENS AS") return makeFavIconUrl("")
    if (buyerName === "CRAYON AS") return makeFavIconUrl("crayon.com")
    if (buyerName === "CUBE IT AS") return makeFavIconUrl("")
    if (buyerName === "DDV") return makeFavIconUrl("ddv.no")
    if (buyerName === "DECA PEOPLE AS") return makeFavIconUrl("")
    if (buyerName === "DELOITTE AS") return makeFavIconUrl("deloitte.com")
    if (buyerName === "DEVOTEAM AS") return makeFavIconUrl("")
    if (buyerName === "DIGITALISERINGSDIREKTORATET") return makeFavIconUrl("digdir.no")
    if (buyerName === "DIREKTORATET FOR FORVALTNING OG ØKONOMISTYRING (DFØ)") return makeFavIconUrl("dfo.no")
    if (buyerName === "DIREKTORATET FOR FORVALTNING OG ØKONOMISTYRING") return makeFavIconUrl("dfo.no")
    if (buyerName === "ELATE AS") return makeFavIconUrl("")
    if (buyerName === "ENTUR AS") return makeFavIconUrl("entur.no")
    if (buyerName === "ENTUR AS") return makeFavIconUrl("entur.no")
    if (buyerName === "ERNST & YOUNG AS") return makeFavIconUrl("")
    if (buyerName === "EVALON AS") return makeFavIconUrl("")
    if (buyerName === "EWORK GROUP NORWAY AS") return makeFavIconUrl("eworkgroup.com")
    if (buyerName === "EXPERIS AS") return makeFavIconUrl("experis.no")
    if (buyerName === "FINK AS") return makeFavIconUrl("")
    if (buyerName === "FLYTOGET AS") return makeFavIconUrl("flytoget.no")
    if (buyerName === "FOLQ AS") return makeFavIconUrl("folq.no")
    if (buyerName === "FORSVARSMATERIELL") return makeFavIconUrl("fma.no")
    if (buyerName === "FORTE DIGITAL AS") return makeFavIconUrl("")
    if (buyerName === "GEVELT CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "GNIST CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "GRITERA AS") return makeFavIconUrl("")
    if (buyerName === "HABBERSTAD AS") return makeFavIconUrl("")
    if (buyerName === "HELLAND CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "HELLAND IT-PARTNER") return makeFavIconUrl("hitpartner.no")
    if (buyerName === "HIVE CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "HOLTE CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "HRP AS") return makeFavIconUrl("")
    if (buyerName === "IKT VALDRES IKS") return undefined // makeFavIconUrl("iktvaldres.no")
    if (buyerName === "INMETA CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "INSAM AS") return makeFavIconUrl("")
    if (buyerName === "INTEGRAL CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "INTEGRERINGS- OG MANGFOLDSDIREKTORATET (IMDI)") return makeFavIconUrl("imdi.no")
    if (buyerName === "INTERLOPE CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "INVENTURA AS") return makeFavIconUrl("inventura.no")
    if (buyerName === "IT CONSULT AS") return makeFavIconUrl("")
    if (buyerName === "IT-PROSJEKTLEDELSE AS") return makeFavIconUrl("")
    if (buyerName === "ITARK AS") return makeFavIconUrl("")
    if (buyerName === "ITERA NORGE AS") return makeFavIconUrl("itera.com")
    if (buyerName === "ITERATE AS") return makeFavIconUrl("iterate.no")
    if (buyerName === "ITSFUN AS") return makeFavIconUrl("")
    if (buyerName === "JOWIT AS") return makeFavIconUrl("")
    if (buyerName === "JPROFESSIONALS AS") return makeFavIconUrl("")
    if (buyerName === "KANTEGA AS") return makeFavIconUrl("kantega.no")
    if (buyerName === "KNOWIT AS") return makeFavIconUrl("knowit.no")
    if (buyerName === "KODE OSLO AS") return makeFavIconUrl("")
    if (buyerName === "KODIO AS") return makeFavIconUrl("")
    if (buyerName === "KOLUMBUS AS") return makeFavIconUrl("kolumbus.no")
    if (buyerName === "KONIK AS") return makeFavIconUrl("")
    if (buyerName === "KONS AS") return makeFavIconUrl("kons.no")
    if (buyerName === "KONSILITO AS") return makeFavIconUrl("")
    if (buyerName === "KPMG AS") return makeFavIconUrl("kpmg.com")
    if (buyerName === "KS (KOMMUNESEKTORENS ORGANISASJON)") return makeFavIconUrl("ks.no")
    if (buyerName === "KS-KOMMUNESEKTORENS ORGANISASJON") return makeFavIconUrl("ks.no")
    if (buyerName === "KYND AS") return makeFavIconUrl("kynd.no")
    if (buyerName === "KYSTVERKET") return makeFavIconUrl("kystverket.no")
    if (buyerName === "LERA AS") return makeFavIconUrl("")
    if (buyerName === "LUND TECH AS") return makeFavIconUrl("")
    if (buyerName === "MARSTRAND AS") return makeFavIconUrl("")
    if (buyerName === "MATTILSYNET") return makeFavIconUrl("mattilsynet.no")
    if (buyerName === "MCKINSEY & COMPANY INC NORWAY NORSK AVDELING AV UTENLANDSK FORETAK") return makeFavIconUrl("")
    if (buyerName === "MELIUS CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "METEOROLOGISK INSTITUTT") return makeFavIconUrl("met.no")
    if (buyerName === "METIER AS") return makeFavIconUrl("")
    if (buyerName === "MILES AS") return makeFavIconUrl("miles.no")
    if (buyerName === "MILES OSLO AS") return makeFavIconUrl("miles.no")
    if (buyerName === "MOVABILITY CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "MULTICONSULT NORGE AS") return makeFavIconUrl("")
    if (buyerName === "NETLIFE DESIGN AS") return makeFavIconUrl("netlife.com")
    if (buyerName === "NETLIGHT AS") return makeFavIconUrl("netlight.com")
    if (buyerName === "NOA IGNITE AS") return makeFavIconUrl("")
    if (buyerName === "NORBAY AS") return makeFavIconUrl("")
    if (buyerName === "NORCONSULT NORGE AS") return makeFavIconUrl("")
    if (buyerName === "NORDIC ADVISER AS") return makeFavIconUrl("")
    if (buyerName === "NORDRE FOLLO KOMMUNE") return makeFavIconUrl("nordrefollo.kommune.no")
    if (buyerName === "NORSK HELSENETT SF") return makeFavIconUrl("nhn.no")
    if (buyerName === "NORSK HELSENETT SF") return makeFavIconUrl("nhn.no")
    if (buyerName === "NORSK RIKSKRINGKASTING AS") return makeFavIconUrl("nrk.no")
    if (buyerName === "NUMERIKA AS") return makeFavIconUrl("")
    if (buyerName === "ODIN PROSJEKT AS") return makeFavIconUrl("")
    if (buyerName === "OKSE AS") return makeFavIconUrl("")
    if (buyerName === "OMEGAPOINT NORGE AS") return makeFavIconUrl("")
    if (buyerName === "OSLO ECONOMICS AS") return makeFavIconUrl("")
    if (buyerName === "PA CONSULTING GROUP AS") return makeFavIconUrl("")
    if (buyerName === "PIVOTIC CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "POINT TAKEN AS") return makeFavIconUrl("")
    if (buyerName === "PRICEWATERHOUSECOOPERS AS") return makeFavIconUrl("")
    if (buyerName === "PROMIS AS") return makeFavIconUrl("")
    if (buyerName === "QUARKS AS") return makeFavIconUrl("")
    if (buyerName === "RAMBØLL NORGE AS") return makeFavIconUrl("")
    if (buyerName === "REDPILL LINPRO AS") return makeFavIconUrl("")
    if (buyerName === "REGISTERENHETEN I BRØNNØYSUND") return makeFavIconUrl("brreg.no")
    if (buyerName === "RELATO AS") return makeFavIconUrl("")
    if (buyerName === "RIGHT AS") return makeFavIconUrl("")
    if (buyerName === "RING DIGITAL AS") return makeFavIconUrl("")
    if (buyerName === "ROBUST CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "RUTER AS") return makeFavIconUrl("ruter.no")
    if (buyerName === "SCOVILLE CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "SENS CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "SEVJA AS") return makeFavIconUrl("")
    if (buyerName === "SHORTCUT AS") return makeFavIconUrl("")
    if (buyerName === "SJØFARTSDIREKTORATET") return makeFavIconUrl("sdir.no")
    if (buyerName === "SJØFARTSDIREKTORATET") return makeFavIconUrl("sdir.no")
    if (buyerName === "SKATTEETATEN") return makeFavIconUrl("skatteetaten.no")
    if (buyerName === "SOLIDSQUARE AS") return makeFavIconUrl("")
    if (buyerName === "SONAT CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "SOPRA STERIA AS") return makeFavIconUrl("soprasteria.no")
    if (buyerName === "SPORVEIEN AS") return makeFavIconUrl("sporveien.no")
    if (buyerName === "STACQ AS") return makeFavIconUrl("")
    if (buyerName === "STATENS KARTVERK") return makeFavIconUrl("kartverket.no")
    if (buyerName === "STATISTISK SENTRALBYRÅ") return makeFavIconUrl("ssb.no")
    if (buyerName === "STATSBYGG") return makeFavIconUrl("statsbygg.no")
    if (buyerName === "STAVANGER KOMMUNE") return makeFavIconUrl("stavanger.kommune.no")
    if (buyerName === "STORTINGET") return makeFavIconUrl("www.stortinget.no")
    if (buyerName === "STRATEGISK RUTEPLAN AS") return makeFavIconUrl("")
    if (buyerName === "STRETCH OPTIMIZE AS") return makeFavIconUrl("")
    if (buyerName === "SWECO NORGE AS") return makeFavIconUrl("")
    if (buyerName === "SYKEHUSINNKJØP HF") return makeFavIconUrl("sykehusinnkjop.no")
    if (buyerName === "SYSINT AS") return makeFavIconUrl("")
    if (buyerName === "SYSTEK AS") return makeFavIconUrl("")
    if (buyerName === "SYSTRA NORGE AS") return makeFavIconUrl("")
    if (buyerName === "TEAKS AS") return makeFavIconUrl("")
    if (buyerName === "TEAKS EIENDOM AS") return makeFavIconUrl("")
    if (buyerName === "TEAKS GRUPPEN AS") return makeFavIconUrl("")
    if (buyerName === "TEAKS KONSULENT AS") return makeFavIconUrl("")
    if (buyerName === "TEAM AGILE AS") return makeFavIconUrl("")
    if (buyerName === "TECHPROS AS") return makeFavIconUrl("")
    if (buyerName === "TECHSEED AS") return makeFavIconUrl("")
    if (buyerName === "TELLMANN EXECUTIVE ADVISORS AS") return makeFavIconUrl("")
    if (buyerName === "TET DIGITAL AS") return makeFavIconUrl("ruter.no")
    if (buyerName === "TIDYA AS") return makeFavIconUrl("")
    if (buyerName === "TIETOEVRY NORWAY AS") return makeFavIconUrl("tietoevry.com")
    if (buyerName === "TIGER CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "TILDE CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "TRIFID NORGE AS") return makeFavIconUrl("")
    if (buyerName === "TRIONA AS") return makeFavIconUrl("")
    if (buyerName === "TURTLESEC AS") return makeFavIconUrl("turtlesec.no")
    if (buyerName === "TWODAY AS") return makeFavIconUrl("")
    if (buyerName === "TWODAY CONCEPTOS AS") return makeFavIconUrl("")
    if (buyerName === "ULRIKEN CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "UNIBUSS AS") return makeFavIconUrl("unibuss.no")
    if (buyerName === "UNICONSULT AS") return makeFavIconUrl("")
    if (buyerName === "UNIVERSITETET I BERGEN") return makeFavIconUrl("uib.no")
    if (buyerName === "VAAR ADVOKAT AS") return makeFavIconUrl("")
    if (buyerName === "VAAR CONSULTING AS") return makeFavIconUrl("")
    if (buyerName === "VALI AS") return makeFavIconUrl("")
    if (buyerName === "VARIANT OSLO AS") return makeFavIconUrl("")
    if (buyerName === "VIANOVA AS") return makeFavIconUrl("")
    if (buyerName === "VYGRUPPEN AS") return makeFavIconUrl("vy.no")
    if (buyerName === "WEBSTEP AS") return makeFavIconUrl("webstep.no")
    if (buyerName === "WSP NORGE AS") return makeFavIconUrl("")
    if (buyerName === "ÅLESUND KOMMUNE") return makeFavIconUrl("alesund.kommune.no")

    if (buyerName.includes("ACHILLES INFORMATION AS")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLES INFORMATION CENTRE")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLES INFORMATION")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLES SYSTEMS AS")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("ACHILLEVS INFORMATION AB")) return makeFavIconUrl("achilles.com")
    if (buyerName.includes("FORSVARET")) return makeFavIconUrl("forsvaret.no")
    if (buyerName.includes("OSLO KOMMUNE")) return makeFavIconUrl("oslo.kommune.no")

    if (buyer.toLowerCase().includes("direktorat")) return undefined
    if (buyer.toLowerCase().includes("fylke")) return undefined
    if (buyer.toLowerCase().includes("kommune")) return undefined

    console.log(`WARN favicon not found for '${buyer}'`)
    return undefined
    // if (buyer === "") return makeFavIconUrl("")
}

export function getBuyerBackgroundColor(buyer: string) {
    if (!buyer) return green[500];
    if (buyer.toLowerCase().includes("direktorat")) return blue[500]
    if (buyer.toLowerCase().includes("fylke")) return yellow[700]
    if (buyer.toLowerCase().includes("kommune")) return orange[700]
    return green[500];
}