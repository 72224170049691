import React, {useEffect, useState} from 'react';
import {Avatar, Box, Card, CardContent, CardHeader, LinearProgress, Link, Stack} from "@mui/material";
import {getNotice} from "../api/ProcurementSystemApi";
import {
    buyerName,
    getNoticeBuyerAddress, getNoticeID,
    getOfficialBuyerAddress,
    getProfileBuyerAddress,
    ProcurementNotice
} from "../types/ProcurementNoticeList";
import {useLocation} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {getBuyerIcon} from "../components/EventIcon";
import LotsCard, {hasLots} from "../components/Cards/LotsCard";
import AmountsCard from "../components/Cards/AmountsCard";
import CpvCodesCard from "../components/Cards/CpvCodesCard";
import DescriptionCard, {hasDescription} from "../components/Cards/DescriptionCard";
import Grid from '@mui/material/Grid';
import WinnerCard from "../components/Cards/WinnerCard";
import TimelineCard from "../components/Cards/TimelineCard";
import {AddressCard, hasAddress} from "../components/Cards/AddressCard";
import {ContactPointCard, hasContactPoint} from "../components/Cards/ContactPointCard";
import LinksCard, {hasLinks} from "../components/Cards/LinksCard";

function getBuyerOrgNumber(result: ProcurementNotice) {
    const buyer = result.buyers[0]
    const nationalID = buyer.id.nationalID
    return nationalID?.registerID + "-" + nationalID?.id
}

function Notice() {
    const location = useLocation(); // Use React Router's location object
    const searchParams = new URLSearchParams(location.search);
    const noticeId = (searchParams.get('noticeID') as string) || null;
    const orgNumber = (searchParams.get('orgNumber') as string) || null;
    const [searchInProgress, setSearchInProgress] = React.useState<boolean>(false);
    const [result, setResult] = useState<ProcurementNotice | undefined>(undefined);

    useEffect(() => {
        // Start search call if we haven't gotten an answer, and we haven't already asked
        if (noticeId && result === undefined && !searchInProgress) {
            setSearchInProgress(true)
            getNotice(noticeId)
                .then((data) => {
                    let notice = data.hits[0];
                    setResult(notice);

                })
                .finally(() => {
                    // Search is no longer in progress
                    setSearchInProgress(false)
                });
        }
    }, [noticeId, result, searchInProgress]);

    let nameOfBuyer = buyerName(result?.buyers[0]);
    return (
        <div>
            {searchInProgress && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
            {result && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Card variant={"outlined"}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    alt={nameOfBuyer}
                                    src={getBuyerIcon(nameOfBuyer)}>
                                    {nameOfBuyer.charAt(0).toUpperCase()}
                                </Avatar>
                            }
                            title={
                                <Typography component={"p"} sx={{wordBreak: "break-word"}} variant={"h4"}>
                                    {getNoticeID(result)} : {result.title.list[0].text}
                                </Typography>
                            }
                            // subheader={`${nameOfBuyer}`}
                            subheader={
                                <Link href={`/organization?orgNumber=${getBuyerOrgNumber(result)}`} underline="always">
                                    {`${nameOfBuyer}`}
                                </Link>
                            }
                        >
                        </CardHeader>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={7}>
                                    <Stack gap={2} direction="column">
                                        {hasDescription(result) &&
                                            <DescriptionCard notice={result}></DescriptionCard>
                                        }
                                        {hasLots(result.lots) &&
                                            <LotsCard lots={result.lots!!}></LotsCard>
                                        }
                                        {result.wonLots && result.wonLots.length > 0 &&
                                            <WinnerCard winners={result.wonLots} orgNumber={orgNumber}/>
                                        }
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2.5}>
                                    <Stack gap={2} direction={"column"}>
                                        <TimelineCard result={result}/>
                                        {hasLinks(result) &&
                                            <LinksCard notice={result}/>
                                        }
                                        {hasAddress(result) && (
                                            <AddressCard buyerOfficialAddress={getOfficialBuyerAddress(result)}
                                                         buyerNoticeAddress={getNoticeBuyerAddress(result)}
                                                         buyerProfileAddress={getProfileBuyerAddress(result)}/>
                                        )}
                                        {hasContactPoint(result) && (
                                            <ContactPointCard contactPoint={result.buyers[0].contactPoint!!}/>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2.5} gap={2} zeroMinWidth>
                                    <Stack gap={2} direction={"column"}>
                                        {result.amounts?.estimatedOverallContract &&
                                            <AmountsCard amounts={result.amounts}/>
                                        }
                                        <CpvCodesCard classification={result.productClassification}/>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </div>
    );
}

export default Notice;