import React from 'react';
import {Box, Card, CardContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Amounts} from "../../types/ProcurementNoticeList";

function AmountsCard(props: { amounts: Amounts | null }) {
    let estimatedOverallContract = props.amounts?.estimatedOverallContract?.formattedValue;
    let frameworkMaximum = props.amounts?.frameworkMaximum?.formattedValue;
    return (<Box>
            <Card variant="outlined" sx={{height: '100%', flexGrow: 1}}>
                <CardContent>
                    <Stack
                        direction="column"
                        sx={{justifyContent: 'space-between', flexGrow: '1', gap: 1}}>
                        <Stack
                            direction="row"
                            sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                            {estimatedOverallContract && (
                                <Stack direction={"column"}>
                                    <Typography variant="subtitle1" component="div">
                                        Estimated Value of Overall Contract
                                    </Typography>
                                    <Typography variant="h5" component="p">
                                        {estimatedOverallContract}
                                    </Typography>
                                </Stack>
                            )}
                            {frameworkMaximum && (
                                <Stack direction={"column"}>
                                    <Typography variant="subtitle1" component="div">
                                        Maximum Value of Framework Agreement
                                    </Typography>
                                    <Typography variant="h5" component="p">
                                        {frameworkMaximum}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                        {!estimatedOverallContract && !frameworkMaximum && (
                            <Stack direction={"column"}>
                                <Typography variant="subtitle1" component="div">
                                    Free-Text Value
                                </Typography>
                                <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                    {props.amounts?.estimatedValueText}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </CardContent>
            </Card></Box>
    );
}

export default AmountsCard;