import * as React from 'react';

import {Route, Router, Switch} from "react-router-dom";
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AppBar from "./components/AppBar";
import {useAuth0} from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import Organization from "./views/Organization";

// styles
import "./App.css";

// fontawesome
import {Box, CssBaseline, CSSInterpolation, LinearProgress} from "@mui/material";

// pages
import Alerts from "./views/Alerts";
import AssignmentView from "./views/AssignmentView";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Notice from "./views/Notice";

const App = () => {
    const {isLoading, error} = useAuth0();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(() => createTheme({
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
        },
        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        background: "transparent",
                        boxShadow: "none",
                        margin: "0px",
                        "&.Mui-expanded": {
                            margin: "0px"
                        },
                    } as CSSInterpolation,
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        marginLeft: "0px",
                        paddingLeft: "0px",
                        justifyContent: "left",
                        textWrap: "nowrap",
                        overflow: "visible",
                        textOverflow: 'ellipsis',
                        flexDirection: 'row-reverse',
                    } as CSSInterpolation,
                    content: {
                        margin: "0px"
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: "2px",
                        paddingBottom: "12px"
                    } as CSSInterpolation,
                }
            }
        },
    }), [prefersDarkMode],);
    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router history={createBrowserHistory()}>
                <AppBar/>
                <Container maxWidth={false} disableGutters={false}>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/profile" component={Profile}/>
                        <Route path="/assignments" component={AssignmentView}/>
                        <Route path="/alerts" component={Alerts}/>
                        <Route path="/notice" component={Notice}/>
                        <Route path="/organization" component={Organization}/>
                    </Switch>
                </Container>
            </Router>
        </ThemeProvider>
    );
};

export default App;
