import React from 'react';
import {Avatar, Card, CardContent, Link, Step, StepLabel, Stepper} from "@mui/material";
import {ProcurementNotice} from "../../types/ProcurementNoticeList";
import Typography from "@mui/material/Typography";
import TimelineDate from "../../types/TimelineDate"
import {blue, green, grey, red, yellow} from "@mui/material/colors";
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import AlarmTwoToneIcon from '@mui/icons-material/AlarmTwoTone';
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import StopCircleTwoToneIcon from '@mui/icons-material/StopCircleTwoTone';
enum Milestone {
    PUBLISHED = "Published",
    DEADLINE = "Deadline",
    START_DATE = "Start Date",
    END_DATE = "End Date"
}

function getTimeLine(notice: ProcurementNotice | undefined): TimelineDate[] {
    let steps: TimelineDate[] = [];
    if (notice === undefined) return steps
    if (notice.tedReferences)
        notice.tedReferences.map((val) => steps.push(new TimelineDate(val)))
    if (notice.doffinReferences)
        notice.doffinReferences.map((val) => steps.push(new TimelineDate(val)))
    if (notice.doffinID?.published)
        steps.push({date: notice.doffinID.published, label: Milestone.PUBLISHED, link: `https://doffin.no/notices/${notice.doffinID.id}`});
    if (notice.tedID?.published)
        steps.push({date: notice.tedID.published, label: Milestone.PUBLISHED, link: `https://ted.europa.eu/en/notice/-/detail/${notice.tedID.id}/`});
    if (notice.submissionDeadline)
        steps.push({date: notice.submissionDeadline, label: Milestone.DEADLINE});
    if (notice.period?.startDate)
        steps.push({date: notice.period.startDate, label: Milestone.START_DATE});
    if (notice.period?.endDate)
        steps.push({date: notice.period.endDate, label: Milestone.END_DATE});
    steps = steps.sort((n1,n2) => {
        if (n1.date === n2.date)
            return 0;
        if (!n1.date)
            return -1
        if (!n2.date)
            return 1;
        if (n1.date > n2.date)
            return 1;
        if (n1.date < n2.date)
            return -1;
        return 0;
    });
    return steps
}

function timelineIconBackground(val: TimelineDate) {
    if (val.icon)
        return grey[50]
    switch (val.label) {
        case Milestone.PUBLISHED: return yellow[500]
        case Milestone.DEADLINE: return red[500]
        case Milestone.START_DATE: return green[500]
        case Milestone.END_DATE: return blue[500]
        default: return "primary.dark"
    }
}

function TimelineCard(props: { result: ProcurementNotice | undefined }) {
    const dates: TimelineDate[] = getTimeLine(props.result)
    const activeStep: number = 0
    return (
        <Card variant="outlined">
            <CardContent>
                {dates &&
                    <Stepper activeStep={activeStep} orientation={"vertical"} sx={{
                        display: "flex",
                    }}>
                        {dates.map((val, index) =>
                            <Step key={index}>
                                <StepLabel icon={
                                    <Avatar
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            bgcolor: timelineIconBackground(val)
                                        }}
                                        style={{
                                            border: val.icon ? '0.1px solid gray' : ""
                                        }}
                                        src={val.icon}>
                                        {val.label === Milestone.PUBLISHED &&
                                            <PendingActionsTwoToneIcon fontSize="small"/>
                                        }
                                        {val.label === Milestone.DEADLINE &&
                                            <AlarmTwoToneIcon fontSize="small"/>
                                        }
                                        {val.label === Milestone.START_DATE &&
                                            <PlayCircleFilledTwoToneIcon fontSize="medium"/>
                                        }
                                        {val.label === Milestone.END_DATE &&
                                            <StopCircleTwoToneIcon fontSize="medium"/>
                                        }
                                    </Avatar>
                                }>
                                    {val.link &&
                                        <Link href={val.link} underline="always" target="_blank">
                                            <Typography variant="subtitle1">{val.label}</Typography>
                                            <Typography variant="caption">{val.date}</Typography>
                                        </Link>
                                    }
                                    {!val.link &&
                                        <>
                                            <Typography variant="subtitle1">{val.label}</Typography>
                                            <Typography variant="caption">{val.date}</Typography>
                                        </>
                                    }
                                </StepLabel>
                            </Step>
                        )}
                    </Stepper>
                }
            </CardContent>
        </Card>
    );
}

export default TimelineCard;