import React from 'react';
import {Card, CardContent, Chip, Stack} from "@mui/material";
import {ProcurementNotice} from "../../types/ProcurementNoticeList";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";

export function hasLinks(notice: ProcurementNotice) {
    let links = filterLinks(notice.links);
    let applyLink = getApplyLink(notice.links);
    return !!((links && links.length > 0) || applyLink);
     // Add notice.mailtoDomains?
}

function getApplyLink(links: string[] | null) {
    if (!links) return undefined
    const urls: URL[] = makeURLs(links)
    const euSupply = urls?.find((url) => url.hostname === "eu.eu-supply.com")
    if (euSupply)
        return euSupply.href
    const mercell = urls?.find((url) => url.hostname === "permalink.mercell.com")
    if (mercell)
        return mercell.href
    const opic = urls?.find((url) => url.hostname === "opic.com")
    if (opic)
        return opic.href
    return undefined
}

function makeURL(link: string) {
    try {
        return new URL(link)
    } catch (e) {
        console.log(`[makeURL] could not resolve link '${link}'`)
        return null
    }
}

function makeURLs(links: string[]) {
    return links.map((link) => makeURL(link)).filter(x => x != null) as URL[]
}

function filterLinks(links: string[] | null) {
    if (!links) return undefined
    const urls: URL[] = makeURLs(links)
    return urls.filter((url) => {
        switch (url.hostname) {
            case "ted.europa.eu":
                return false
            case "www.doffin.no":
                return false
            case "kgv.doffin.no":
                return false
            case "eu.eu-supply.com":
                return false
            case "permalink.mercell.com":
                return false
            case "opic.com":
                return false
            default :
                return true
        }
    })
}

function LinksCard(props: { notice: ProcurementNotice }) {
    const notice = props.notice;

    const applyLink = getApplyLink(props.notice.links)

    return (notice &&
        <Card variant="outlined">
            <CardContent>
                <Stack sx={{justifyContent: 'space-between', flexGrow: '1', gap: 1}}>
                    {applyLink && (
                        <Chip
                            label="Apply"
                            icon={<DriveFileRenameOutlineRoundedIcon/>}
                            color="success"
                            component="a"
                            target="_blank"
                            href={applyLink}
                            clickable={true}
                        />
                    )}
                    {hasLinks(notice) && (
                        notice.links && filterLinks(notice.links)?.map((url, index) =>
                            <Chip
                                key={index}
                                label={url.hostname}
                                color="info"
                                component="a"
                                target="_blank"
                                href={url.href}
                                clickable={true}
                            />
                        )
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}

export default LinksCard;