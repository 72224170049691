import { EmojiEvents } from "@mui/icons-material";
import {Avatar, Card, CardContent, CardHeader, Chip, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from 'react';
import {
    buyerName,
    getNoticeID,
    ProcurementNotice
} from "../../types/ProcurementNoticeList";
import { useHistory } from "react-router-dom";
import {getBuyerBackgroundColor, getBuyerIcon} from "../EventIcon";
import {grey} from "@mui/material/colors";

function WonNoticesCard(props: { notices: ProcurementNotice[], orgNumber: string | null }) {
    const notices = props.notices;
    const [count, setCount] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setCount(notices.length);
    }, [notices]);

    const handleClick = (noticeId: string) => {
        if (noticeId) {
            history.push(`/notice?noticeID=${noticeId}&orgNumber=${props.orgNumber}`);
        }
    };

    const handleMiddleClick = (noticeId: string | undefined, event: any) => {
        if (noticeId && event.button === 1) {
            window.open(`/notice?noticeID=${noticeId}`, '_blank')
        }
    };

    return (
        <Card variant={"outlined"}>
            <CardContent>
                <Stack direction="column" sx={{justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                    <Typography variant="h5">
                        Won Notices <EmojiEvents fontSize="inherit" color="inherit"/>
                    </Typography>
                    {count !== 0 && <Chip color="success" variant={"outlined"} label={count}/>}
                </Stack>
                <Stack direction="column" sx={{justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                    {notices?.map((val, index) => {
                            let nameOfBuyer = buyerName(val.buyers[0]);
                            let buyerIcon = getBuyerIcon(nameOfBuyer);
                            let backgroundColor = getBuyerBackgroundColor(nameOfBuyer);
                            return <Card
                                key={index}
                                variant="outlined"
                                data-testid="card"
                                sx={{
                                    // display: "flex",
                                    alignSelf: "stretch",
                                    margin: 1,
                                    cursor: "pointer",
                                    transition: "transform 0.2s, box-shadow 0.2s",
                                    '&:hover': {
                                        transform: "scale(1.05)",
                                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                    },
                                }}
                                onClick={() => handleClick(getNoticeID(val))}
                                onMouseDown={(event) => handleMiddleClick(getNoticeID(val), event)}
                            >
                                <Stack direction="row">
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                alt={nameOfBuyer}
                                                src={buyerIcon}
                                                sx={{
                                                    bgcolor: buyerIcon ? grey[50] : backgroundColor,
                                                    width: 32,
                                                    height: 32,
                                                }}
                                                style={{
                                                    border: '1px solid gray'
                                                }}
                                            >
                                                {nameOfBuyer.charAt(0).toUpperCase()}
                                            </Avatar>
                                        }
                                        title={
                                            <Typography variant="h6" component="p"
                                                        sx={{wordBreak: "break-word", overflow: 'hidden'}}>
                                                {/*{nameOfBuyer}*/}
                                                {`${getNoticeID(val)} : ${val.title.list[0].text}`}
                                            </Typography>
                                        }
                                        subheader={`${nameOfBuyer}`}
                                    />
                                    <CardContent>
                                    </CardContent>
                                </Stack>
                            </Card>;
                        }
                    )}
                </Stack>
                {count === 0 && <Typography variant="caption">No Notices won</Typography>}
            </CardContent>
        </Card>
    );
}

export default WonNoticesCard;
