import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {formatLocalizedString, getLanguageString, getNoticeDescription, Lot} from "../../types/ProcurementNoticeList";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function hasLots(lots: Lot[] | null) {
    return lots && lots.length > 0;
}

function LotsCard(props: { lots: Lot[] }) {
    const lots = props.lots;
    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };

    return (
        <Card variant="outlined" sx={{display: 'flex', flexDirection: 'column'}}>
            {(props.lots.length === 1) && (
                <>
                    <CardHeader
                        title={"LOT-0001"}
                        subheader={getLanguageString(props.lots[0].title)}
                    />
                    <CardContent>
                        {formatLocalizedString(getNoticeDescription(lots[0]?.description))?.map((paragraph, index) =>
                            <Typography
                                variant="body2"
                                sx={{wordBreak: "break-word"}}
                                align={'left'} key={index}
                            >
                                {index > 0 ? `- ${paragraph}` : paragraph}
                            </Typography>
                        )}
                    </CardContent>
                </>
            )}
            {(props.lots.length > 1) && (
                <CardContent>
                    <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons
                          allowScrollButtonsMobile>
                        {lots && lots?.map((lot, index) =>
                            <Tab
                                value={index}
                                label={lot.title && (
                                    <Typography align={'left'}>
                                        {getLanguageString(lot.title)}
                                    </Typography>
                                )}
                            />
                        )}
                    </Tabs>
                    {lots && lots?.map((lot, index) =>
                        <TabPanel value={value} index={index}>
                            <Typography
                                variant="body2"
                                sx={{wordBreak: "break-word"}}
                            >
                                {formatLocalizedString(getNoticeDescription(lot.description))?.map((paragraph, index) =>
                                    <Typography align={'left'}>
                                        {index > 0 ? `- ${paragraph}` : paragraph}
                                    </Typography>
                                )}
                            </Typography>
                        </TabPanel>
                    )}
                </CardContent>
            )}
        </Card>
    )
}

export default LotsCard;