import { Box, Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { OrganizationRecord } from 'src/types/OrganizationRecordList';

function OrganizationDescriptionCard(props: { organization: OrganizationRecord }) {
    const organization = props.organization;
    return (
        <Card variant="outlined">
            <CardContent>
                {organization.street && <>
                    <Typography variant="subtitle1" component="div">
                        Address :
                    </Typography>
                    <Box>
                        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                            {organization.street}, {organization.postcode}<br /> {organization.town}
                        </Typography>
                    </Box>
                </>}
            </CardContent>
        </Card>
    );
}

export default OrganizationDescriptionCard;