import {Avatar, Box, Card, CardContent, CardHeader, LinearProgress, Stack} from "@mui/material";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import OrganizationDescriptionCard from "src/components/Cards/OrganizationDescriptionCard";
import WonNoticesCard from "src/components/Cards/WonNoticesCard";
import {getBuyerBackgroundColor, getBuyerIcon} from "src/components/EventIcon";
import {getOrganization, getPurchasingSystems} from "../api/ProcurementSystemApi";
import PublishedNoticesCard from "../components/Cards/PublishedNoticesCard";
import {buyerName, OrganizationRecord} from "../types/OrganizationRecordList";
import {ProcurementNotice} from "../types/ProcurementNoticeList";
import {grey} from "@mui/material/colors";

function Organization() {
    const location = useLocation(); // Use React Router's location object
    const searchParams = new URLSearchParams(location.search);
    const orgNumber = (searchParams.get('orgNumber') as string) || null;
    const [searchInProgress, setSearchInProgress] = React.useState<boolean>(false);
    const [result, setResult] = useState<OrganizationRecord | undefined>(undefined);
    const [publishedNotice, setPublishedNotice] = useState<ProcurementNotice[]>();
    const [winnerNotices, setWinnerNotices] = useState<ProcurementNotice[]>();

    useEffect(() => {

        function getPublishedProcurementNotices(notices: ProcurementNotice[] | null) {
            if (notices !== null) {
                return notices.filter(notice =>
                    `${notice.buyers[0].id.nationalID?.registerID}-${notice.buyers[0].id.nationalID?.id}` === orgNumber
                );
            }
            return [];
        }

        function getWonProcurementNotices(notices: ProcurementNotice[] | null) {
            if (notices !== null) {
                return notices.filter(notice =>
                    notice.wonLots?.some(lot =>
                        lot.winner?.some(
                            winner => winner.id.nationalID && `${winner.id.nationalID?.registerID}-${winner.id.nationalID?.id}` === orgNumber
                        )
                    )
                );
            }
            return [];
        }

        if (orgNumber && result === undefined && !searchInProgress) {
            setSearchInProgress(true)
            getOrganization(orgNumber)
                .then((data) => {
                    setResult(data.hits[0]);
                })
                .finally(() => {
                    setSearchInProgress(false)
                });
            getPurchasingSystems()
                .then((data) => {
                    let buyer = data;
                    let published = getPublishedProcurementNotices(buyer.hits);
                    setPublishedNotice(published);
                    let won = getWonProcurementNotices(buyer.hits);
                    setWinnerNotices(won);
                })
                .finally(() => {
                    setSearchInProgress(false)
                });
        }
    }, [orgNumber, result, searchInProgress]);

    let nameOfBuyer = buyerName(result);
    let buyerIcon = getBuyerIcon(nameOfBuyer);
    let backgroundColor = getBuyerBackgroundColor(nameOfBuyer);
    return (
        <div>
            {searchInProgress && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            {result && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginY: "16px", display: "block", height: "100vh" }}>
                    <Card variant={"outlined"}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    alt={nameOfBuyer}
                                    src={buyerIcon}
                                    sx={{
                                        // width: 24,
                                        // height: 24,
                                        bgcolor: buyerIcon ? grey[50] : backgroundColor
                                    }}
                                    style={{
                                        border: '0.2px solid gray'
                                    }}
                                >
                                    {nameOfBuyer.charAt(0).toUpperCase()}
                                </Avatar>
                            }
                            title={
                                <Stack>
                                    <Typography component={"p"} sx={{wordBreak: "break-word"}} variant={"h4"}>
                                        {result.officialName}
                                    </Typography>
                                    <Typography component={"p"} sx={{wordBreak: "break-word"}} variant={"h5"}>
                                        {result.nationalID?.toUpperCase() || ""}
                                    </Typography>
                                </Stack>
                            }
                        >
                        </CardHeader>
                        <CardContent sx={{ display: "inline-flex" }}>
                            <Grid container
                                spacing={2} sx={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    width: "92vw",
                                    padding: "16px"
                                }}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Stack gap={2} direction="column">
                                        <OrganizationDescriptionCard organization={result}></OrganizationDescriptionCard>
                                        {(winnerNotices && winnerNotices.length > 0)  &&
                                            <WonNoticesCard notices={winnerNotices} orgNumber={orgNumber} />
                                        }
                                        {(publishedNotice && publishedNotice.length > 0) &&
                                            <PublishedNoticesCard notices={publishedNotice} orgNumber={orgNumber} />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </div>
    );
}


export default Organization;