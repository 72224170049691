import {DoffinID, NoticeID, TedID} from "./ProcurementNoticeList";

interface TimelineDateInterface {
    date: string | null,
    label: string,
    icon?: string
}

export default class TimelineDate implements TimelineDateInterface {
    constructor(
        noticeID: DoffinID | TedID | NoticeID
    ) {
        if (noticeID.platform === "DOFFIN") {
            this.date = noticeID.published
            this.label = `${noticeID.platform}-${noticeID.id}`
            this.icon = "https://s2.googleusercontent.com/s2/favicons?domain=dfo.no"
            this.link = `https://doffin.no/notices/${noticeID.id}`
        } else if (noticeID.platform === "TED") {
            this.date = noticeID.published
            this.label = `${noticeID.platform}-${noticeID.id}`
            this.icon = "https://s2.googleusercontent.com/s2/favicons?domain=europa.eu"
            this.link = `https://ted.europa.eu/en/notice/-/detail/${noticeID.id}`
        } else {
            this.date = ""
            this.label = ""
            this.icon = ""
            this.link = ""
        }
    }

    date: string | null;
    label: string;
    icon?: string;
    link?: string;
}
