import React from 'react';
import {LocalizedText, OrgNumber, Winner, WonLot} from "../../types/ProcurementNoticeList";
import Typography from "@mui/material/Typography";
import {EmojiEvents} from "@mui/icons-material";
import {Avatar, Card, CardContent, CardHeader, Chip, Stack} from "@mui/material";
import {getBuyerBackgroundColor, getBuyerIcon} from "../EventIcon";
import {useHistory} from 'react-router-dom';
import {grey} from "@mui/material/colors";

function winnerName(winner: Winner) {
    let id = winner.id;
    if (id.officialName)
        return id.officialName
    if (id.name) {
        let localizedName = id.name?.list;
        let norwegian = localizedName?.filter((localized: LocalizedText) => localized.languageID === "NOR" || localized.languageID === "NOB" || localized.languageID === "NNO")
        if (norwegian && norwegian.length > 0)
            return norwegian[0].text
        let english = localizedName?.filter((localized: LocalizedText) => localized.languageID === "ENG")
        if (english && english.length > 0)
            return english[0].text
    }
    return "OMITTED";
}

function WinnerCard(props: { winners: WonLot[], orgNumber: string | null }) {

    const history = useHistory();

    const handleClick = (nationalId: OrgNumber | null) => {
        if (nationalId) {
            history.push(`/organization?orgNumber=${nationalId.registerID}-${nationalId.id}`);
        }
    };

    const handleMiddleClick = (nationalId: OrgNumber | null, event: any) => {
        if (nationalId && event.button === 1) {
            window.open(`/organization?orgNumber=${nationalId.registerID}-${nationalId.id}`, '_blank')
        }
    };

    function currentWinner(wonLots: WonLot[] | null) {
        if (props.orgNumber && wonLots != null) {
            const lotsWon = wonLots.find(lot =>
                lot.winner?.some(
                    winner => winner.id.nationalID && winner.id.nationalID.id === props.orgNumber
                )
            );
            return lotsWon?.winner?.find(winner => winner.id.nationalID && winner.id.nationalID.id === props.orgNumber);
        }
        return undefined;
    }

    let highlightedWinner = currentWinner(props.winners);
    return (<Card variant={"outlined"}>
            <CardContent>
                <Stack direction="column" spacing={1} sx={{marginBottom: 1, justifyContent: 'center'}}>
                    <Typography variant="h5" sx={{alignSelf: 'center'}}>
                        Awarded <EmojiEvents fontSize="inherit" color="success"/>
                    </Typography>
                    {highlightedWinner && (
                        <Chip
                            color="success"
                            variant={"outlined"}
                            label={`Won by ${winnerName(highlightedWinner)}`}
                            sx={{alignSelf: 'center'}}
                        />
                    )}
                </Stack>
                {props.winners.map((lot, lotIndex) =>
                    lot.winner?.map((val, index) => {
                        let nameOfWinner = winnerName(val);
                        let backgroundColor = getBuyerBackgroundColor(nameOfWinner);
                        let winnerIcon = getBuyerIcon(nameOfWinner);
                        let isWinner = props.orgNumber === val.id.nationalID?.id;
                        return (
                            <Stack key={`${lotIndex}-${index}`}>
                                <Card
                                    key={val.id.nationalID?.id}
                                    variant="outlined"
                                    data-testid="card"
                                    sx={{
                                        backgroundColor: isWinner ? "success.dark" : "",
                                        color: isWinner ? "success.contrastText" : "",
                                        cursor: "pointer",
                                        transition: "transform 0.2s, box-shadow 0.2s",
                                        '&:hover': {
                                            transform: "scale(1.05)",
                                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                        },
                                    }}
                                    onClick={() => handleClick(val.id.nationalID)}
                                    onMouseDown={(event) => handleMiddleClick(val.id.nationalID, event)}
                                >
                                    <Stack gap={2} direction="row">
                                        <CardHeader
                                            sx={{width: '50%'}}
                                            avatar={
                                                <Avatar
                                                    alt={nameOfWinner}
                                                    src={winnerIcon}
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        bgcolor: winnerIcon ? grey[50] : backgroundColor
                                                    }}
                                                    style={{
                                                        border: '0.2px solid gray'
                                                    }}
                                                >
                                                    {nameOfWinner.charAt(0).toUpperCase()}
                                                </Avatar>
                                            }
                                            title={nameOfWinner}
                                        />
                                        <CardContent>
                                            {val.address.map((add, index) => (
                                                <Typography
                                                    key={index}
                                                    sx={{
                                                        color:
                                                            isWinner
                                                                ? "success.contrastText"
                                                                : 'text.secondary',
                                                    }}
                                                >
                                                    {add.country?.text} {add.countrySubEntity?.text}
                                                </Typography>
                                            ))}
                                        </CardContent>
                                    </Stack>
                                </Card>
                            </Stack>
                        );
                    })
                )}
            </CardContent>
        </Card>
    );
}

export default WinnerCard;
