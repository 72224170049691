import React from "react";
import {CpvCode} from "../../types/ProcurementNoticeList";
import {Avatar} from "@mui/material";
import AgricultureTwoToneIcon from "@mui/icons-material/AgricultureTwoTone";
import LocalGasStationTwoToneIcon from "@mui/icons-material/LocalGasStationTwoTone";
import EngineeringTwoToneIcon from "@mui/icons-material/EngineeringTwoTone";
import BakeryDiningTwoToneIcon from "@mui/icons-material/BakeryDiningTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import CheckroomTwoToneIcon from "@mui/icons-material/CheckroomTwoTone";
import ColorLensTwoToneIcon from '@mui/icons-material/ColorLensTwoTone';
import StorageTwoToneIcon from "@mui/icons-material/StorageTwoTone";
import ElectricalServicesTwoToneIcon from "@mui/icons-material/ElectricalServicesTwoTone";
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import LocalPoliceTwoToneIcon from "@mui/icons-material/LocalPoliceTwoTone";
import ChairTwoToneIcon from "@mui/icons-material/ChairTwoTone";
import ScienceTwoToneIcon from "@mui/icons-material/ScienceTwoTone";
import PrecisionManufacturingTwoToneIcon from "@mui/icons-material/PrecisionManufacturingTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import BuildTwoToneIcon from "@mui/icons-material/BuildTwoTone";
import HotelTwoToneIcon from "@mui/icons-material/HotelTwoTone";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import ShowChartTwoToneIcon from "@mui/icons-material/ShowChartTwoTone";
import ArchitectureTwoToneIcon from "@mui/icons-material/ArchitectureTwoTone";
import ComputerTwoToneIcon from "@mui/icons-material/ComputerTwoTone";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import MedicalServicesTwoToneIcon from "@mui/icons-material/MedicalServicesTwoTone";
import RecyclingTwoToneIcon from "@mui/icons-material/RecyclingTwoTone";
import SpaTwoToneIcon from '@mui/icons-material/SpaTwoTone';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';
import LocalPostOfficeTwoToneIcon from '@mui/icons-material/LocalPostOfficeTwoTone';
import TungstenTwoToneIcon from '@mui/icons-material/TungstenTwoTone';
import BatteryChargingFullTwoToneIcon from '@mui/icons-material/BatteryChargingFullTwoTone';
import WaterDropTwoToneIcon from '@mui/icons-material/WaterDropTwoTone';
import BrushTwoToneIcon from '@mui/icons-material/BrushTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import RadioTwoToneIcon from '@mui/icons-material/RadioTwoTone';
import CarpenterTwoToneIcon from '@mui/icons-material/CarpenterTwoTone';
import BiotechTwoToneIcon from '@mui/icons-material/BiotechTwoTone';
import DrawTwoToneIcon from '@mui/icons-material/DrawTwoTone';
import ForestTwoToneIcon from '@mui/icons-material/ForestTwoTone';
import ParkTwoToneIcon from '@mui/icons-material/ParkTwoTone';
import {
    blue,
    blueGrey,
    brown, cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    pink,
    purple,
    red,
    teal,
    yellow
} from "@mui/material/colors";

function getAvatarColor(code: string) {
    switch(code.substring(0,2)) {
        case "01": return grey[500]
        case "02": return green[500]
        case "03": return grey[500]
        case "09": return grey[800]
        case "11": return grey[800]
        case "14": return blueGrey[800]
        case "15": return lime[500]
        case "16": return grey[500]
        case "17": return teal[800]
        case "18": return teal[800]
        case "19": return teal[800]
        case "20": return green[500]
        case "22": return brown[300]
        case "24": return lime[900]
        case "25": return red[900]
        case "26": return blue[500]
        case "27": return blue[300]
        case "28": return blue[500]
        case "29": return grey[300]
        case "30": return purple[200]
        case "31": return yellow[700]
        case "32": return yellow[500]
        case "33": return red[900]
        case "34": return grey[700]
        case "35": return deepOrange[700]
        case "36": return brown[300]
        case "37": return pink[800]
        case "38": return green[500]
        case "39": return cyan[500]
        case "40": return yellow[700]
        case "41": return blue[700]
        case "42": return blueGrey[200]
        case "43": return blueGrey[300]
        case "44": return blueGrey[700]
        case "45": return lime[500]
        case "48": return deepPurple[300]
        case "50": return deepOrange[200]
        case "51": return deepOrange[200]
        case "55": return blue[300]
        case "60": return grey[400]
        case "63": return teal[400]
        case "64": return lightBlue[400]
        case "65": return yellow[400]
        case "66": return green[300]
        case "67": return green[800]
        case "70": return teal[500]
        case "71": return teal[500]
        case "72": return deepPurple[500]
        case "73": return green[500]
        case "74": return blue[500]
        case "75": return blue[500]
        case "76": return grey[800]
        case "77": return grey[300]
        case "78": return purple[300]
        case "79": return grey[500]
        case "80": return brown[500]
        case "85": return red[900]
        case "90": return green[800]
        case "92": return lightGreen[500]
        case "98": return indigo[500]
        default: return pink[500]
    }
}

export class CpvAvatar extends React.Component<{ val: CpvCode }> {
    render() {
        let avatarColor = getAvatarColor(this.props.val.id.code);
        return <Avatar
            sx={{
                bgcolor: avatarColor,
                width: 32,
                height: 32
            }}
        >
            {this.props.val.id.code.startsWith("01") &&
                <AgricultureTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("02") &&
                <ForestTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("03") &&
                <AgricultureTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("09") &&
                <LocalGasStationTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("11") &&
                <LocalGasStationTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("14") &&
                <EngineeringTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("15") &&
                <BakeryDiningTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("16") &&
                <AgricultureTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("17") &&
                <CheckroomTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("18") &&
                <CheckroomTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("19") &&
                <CheckroomTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("20") &&
                <ParkTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("22") &&
                <MenuBookTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("24") &&
                <ScienceTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("25") &&
                <MedicalServicesTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("26") &&
                <CategoryTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("27") &&
                <CategoryTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("28") &&
                <CategoryTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("29") &&
                <AgricultureTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("30") &&
                <StorageTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("31") &&
                <ElectricalServicesTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("32") &&
                <RadioTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("33") &&
                <MedicalServicesTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("34") &&
                <LocalShippingTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("35") &&
                <LocalPoliceTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("36") &&
                <ChairTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("37") &&
                <BrushTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("38") &&
                <ScienceTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("39") &&
                <ChairTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("40") &&
                <BatteryChargingFullTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("41") &&
                <WaterDropTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("42") &&
                <PrecisionManufacturingTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("43") &&
                <PrecisionManufacturingTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("44") &&
                <CarpenterTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("45") &&
                <HandymanTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("48") &&
                <AnalyticsTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("50") &&
                <BuildTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("51") &&
                <BuildTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("55") &&
                <HotelTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("60") &&
                <LocalShippingTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("63") &&
                <LocalShippingTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("64") &&
                <LocalPostOfficeTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("65") &&
                <TungstenTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("66") &&
                <AccountBalanceTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("67") &&
                <ShowChartTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("70") &&
                <ApartmentTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("71") &&
                <ArchitectureTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("72") &&
                <ComputerTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("73") &&
                <BiotechTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("74") &&
                <BusinessCenterTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("75") &&
                <FeedTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("76") &&
                <LocalGasStationTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("77") &&
                <AgricultureTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("78") &&
                <DrawTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("79") &&
                <PrintTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("80") &&
                <SchoolTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("85") &&
                <MedicalServicesTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("90") &&
                <RecyclingTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("92") &&
                <ColorLensTwoToneIcon fontSize="small"/>
            }
            {this.props.val.id.code.startsWith("98") &&
                <SpaTwoToneIcon fontSize="small"/>
            }
        </Avatar>;
    }
}