export interface ProcurementNoticeList {
    numHitsTotal: number
    numHitsAccessible: number
    hits: ProcurementNotice[]
}

export interface DoffinID {
    id: string
    published: string | null
    platform: string // enum PlatformID
}

export interface TedID {
    id: string
    coll: string | null
    no: string | null
    published: string | null
    noDoc: string | null
    validated: boolean
    platform: string // enum PlatformID
}

export interface NoticeID {
    id: string
    published: string | null
    platform: string // enum PlatformID
    formTypes: string[] // enum NoticeFormType
}

export interface ProcurementID {
    id: string
    platform: string // enum PlatformID
}

export function noticeID(notice: ProcurementNotice): string | null {
    if (notice.doffinID)
        return `${notice.doffinID.platform}-${notice.doffinID.id}`
    if (notice.tedID)
        return `${notice.tedID.platform}-${notice.tedID.id}`
    return null
}

export interface ProcurementNotice {
    doffinID: DoffinID | null
    tedID: TedID | null
    norskLysingsBladID: ProcurementID[]
    doffinSearchID: ProcurementID[]
    procurementIDs: ProcurementID[]
    customID: string[] | null
    submissionDeadline: string | null
    buyers: Buyer[]
    informationProvider: any
    title: LocalizedString
    metaData: NoticeMetaData
    doffinReferences: NoticeID[] | null
    tedReferences: NoticeID[] | null
    links: string[] | null
    mailtoDomains: string[] | null
    productClassification: ProductClassification
    contractingSystemFA: boolean | null
    contractingSystemDPS: boolean | null
    contractingSystemPC: boolean | null
    dividedIntoLots: boolean | null
    numberOfLots: number | null
    location: Location
    amounts: Amounts | null
    period: Period | null
    lots: Lot[] | null
    wonLots: WonLot[] | null
    offersReceived: number | null
    recordMetaData: RecordMetaData
    country: string | null // enum CountryCode
    description: NoticeDescription | null
    note: LocalizedString | null
    status: string | null
}

export function getNoticeID(notice: ProcurementNotice) {
    if (notice.doffinID) {
        let noticeID = notice.doffinID;
        return noticeID?.platform + "-" + noticeID?.id;
    } else {
        let noticeID = notice.tedID;
        return noticeID?.platform + "-" + noticeID?.id;
    }
}

export function getDescription(val: ProcurementNotice) {
    let noticeDescription = getNoticeDescription(val.description);
    if (noticeDescription) {
        return noticeDescription
    }
    let lotDescriptions = val.lots?.map((lot: Lot) => lot.description);
    if (lotDescriptions && lotDescriptions.length === 1 && lotDescriptions[0]) {
        let lotDescription = getNoticeDescription(lotDescriptions[0]);
        if (lotDescription) {
            return lotDescription
        }
    }
    return null;
}

export function getNoticeDescription(noticeDescription: NoticeDescription | null) {
    if (noticeDescription) {
        if (noticeDescription.shortContractDescription)
            return noticeDescription.shortContractDescription
        if (noticeDescription.totalQuantityOrScope)
            return noticeDescription.totalQuantityOrScope
        if (noticeDescription.additionalInformation)
            return noticeDescription.additionalInformation
        if (noticeDescription.economicOperatorsPersonalSituation)
            return noticeDescription.economicOperatorsPersonalSituation
    }
    return null;
}

export interface Buyer {
    id: OrgIdentifier
    address: Place[] | null
    contactPoint: ContactPoint | null
    source: string[] | null // enum OrgSource
}

export function buyerName(buyer: Buyer | undefined) {
    if (!buyer) return "OMITTED";
    let id = buyer.id;
    if (id.officialName)
        return id.officialName
    if (id.name) {
        let localizedName = id.name?.list;
        let norwegian = localizedName?.filter((localized: LocalizedText) => localized.languageID === "NOR" || localized.languageID === "NOB" || localized.languageID === "NNO")
        if (norwegian && norwegian.length > 0)
            return norwegian[0].text
        let english = localizedName?.filter((localized: LocalizedText) => localized.languageID === "ENG")
        if (english && english.length > 0)
            return english[0].text
    }
    return "OMITTED";
}

export enum AddressKind {
    OFFICIAL_OCCURRENCE = "Official Address",
    NOTICE_OCCURRENCE = "Notice Address",
    PROFILE_OCCURRENCE = "Profile Address"
}

export function getNoticeBuyerAddress(notice: ProcurementNotice) {
    if (notice.buyers.length === 0)
        return undefined
    let firstBuyer = notice.buyers[0]
    let addresses = firstBuyer.address;
    if (!addresses || addresses.length === 0)
        return undefined
    let noticeAddr = addresses.filter((addr: Place) => addr.kinds.some((kind: string) => kind === "NOTICE_OCCURRENCE"))
    if (noticeAddr && noticeAddr.length > 0)
        return noticeAddr[0]
    return undefined
}

export function getProfileBuyerAddress(notice: ProcurementNotice) {
    if (notice.buyers.length === 0)
        return undefined
    let firstBuyer = notice.buyers[0]
    let addresses = firstBuyer.address;
    if (!addresses || addresses.length === 0)
        return undefined
    let profile = addresses.filter((addr: Place) => addr.kinds.some((kind: string) => kind === "PROFILE_OCCURRENCE"))
    if (profile && profile.length > 0)
        return profile[0]
    return undefined
}

export function getOfficialBuyerAddress(notice: ProcurementNotice) {
    if (notice.buyers.length === 0)
        return undefined
    let firstBuyer = notice.buyers[0]
    let addresses = firstBuyer.address;
    if (!addresses || addresses.length === 0)
        return undefined
    let official = addresses.filter((addr: Place) => addr.kinds.some((kind: string) => kind === "OFFICIAL_OCCURRENCE"))
    if (official && official.length > 0)
        return official[0]
    return undefined
}

export interface ContactPoint {
    name: string | null
    email: string | null
    telephone: string | null
    fax: string | null
}

export interface OrgIdentifier {
    name: LocalizedString
    officialName: string | null
    nationalID: OrgNumber | null
    profileID: OrgProfileID[] | null
    orgID: OrgID
    source: string[] | null // enum OrgSource
}

export interface OrgNumber {
    id: string
    registerID: string // enum OrgNumberRegister
}

export function buildOrgNumber(num: string): OrgNumber {
    if (!num.includes("-"))
        return {id : num, registerID: "BRREG"} as OrgNumber
    let [registerID, ...rest] = num.split('-')
    let id = rest.join('-')
    return {id : id, registerID: registerID} as OrgNumber
}

export interface OrgProfileID {
    id: string
    platform: string
}

export interface OrgID {
    id: string
    documentID: DocumentID | null
}

export interface DocumentID {
    id: string
}

export interface Place {
    department: string | null
    street: string | null
    town: string | null
    postcode: string | null
    country: TextCode | null
    countrySubEntity: TextCode | null
    region: string | null
    desc: LocalizedString | null
    kinds: string[] // enum AddressKind
}

export interface TextCode {
    code: string
    listName: string
    text: string
}

export interface NoticeMetaData {
    formTypes: string[]          // enum NoticeFormType
    procedureType: string[]      // enum NoticeProcedureType
    contractType: string[]       // enum NoticeContractType
    legalBasis: string[]         // NoticeLegalBasis
    contractingSystems: string[] // NoticeContractingSystem
}

// export interface FormType {
//     code: string
//     label: string
// }
//
// export interface ContractType {
//     code: string
//     label: string
// }
//
// export interface LegalBasis {
//     code: string
//     label: string
// }

export interface ProductClassification {
    mainCpv: CpvCode[] | null
    additionalCpvs: CpvCode[] | null
    commodityCodes: CommodityContextList | null
}

export interface CommodityContextList {
    contexts: CommodityContext[]
}

export interface CommodityContext {
    codes: CommodityCode[]
    type: string | null // enum CommodityContextType
}

export interface CommodityCode {
    id: string
    label: LocalizedString
}

export interface CpvCode {
    id: CpvID
    label: LocalizedString | null
    version: string
    correspondingCpv: CpvID | null
}

export interface CpvID {
    code: string
}

export interface Location {
    nutsContextList: NutsContextList | null
    locationContextList: LocationContextList | null
    locationDescription: LocationDescription | null
}

export interface NutsContextList {
    contexts: NutsContext[]
}

export interface NutsContext {
    codes: NutsCode[]
    type: string | null // enum NutsContextType
}

export interface LocationContextList {
    contexts: LocationContext[]
}

export interface LocationContext {
    codes: LocationCode[]
    type: string | null // enum LocationContextType
}

export interface LocationCode {
    id: string
    label: LocalizedString
}

export interface LocationDescription {
    contexts: string[]
}

export interface Amounts {
    estimatedOverallContract: Amount | null
    frameworkMaximum: Amount | null
    estimatedValueText: string | null
}

export interface Amount {
    value: number | null
    currency: string | null
    localizedText: string | null
    formattedValue: string | null
    excludingVAT: boolean | null
}

export interface Period {
    startDate: string | null
    endDate: string | null
    duration: string | null
}

export interface LotID {
    id: string,
    type: string
}

export interface LegacyLotID {
    id: string
}

export interface LotIdentifier {
    lotID: LotID,
    legacyLotID: LegacyLotID | null,
    customID: string | null
}

export interface Lot {
    lotIdentifier: LotIdentifier
    title: LocalizedString | null
    description: NoticeDescription | null
    amounts: Amounts | null
    productClassification: ProductClassification | null
}

export interface ContractID {
    id: string
}

export interface WonLot {
    lotIdentifier: LotIdentifier
    contractID: ContractID | null
    awardDate: string | null
    winner: Winner[] | null
    offersReceived: string | null
    isLikelySubContracted: boolean | null
}

export interface Winner {
    id: OrgIdentifier
    address: Place[]
    contactPoint: ContactPoint | null
    source: string[] // enum OrgSource
}

export interface LocalizedString {
    list: LocalizedText[]
}

export function formatLocalizedString(description: LocalizedString | null) {
    const raw: string | undefined = getLanguageString(description);
    if (!raw) return []
    return formatString(raw)
}

export function formatString(raw: string | null) {
    if (!raw) return []
    return raw.replaceAll ('·', '•').replaceAll(" - ", " • ").split("•")
}

export function getLanguageString(localized: LocalizedString | null): string | undefined {
    if (!localized) return undefined
    let localizedList = localized.list;
    if (!localizedList || localizedList.length === 0) return undefined

    let norwegian = localizedList?.filter((localized: LocalizedText) => localized.languageID === "NOR" || localized.languageID === "NOB" || localized.languageID === "NNO")
    if (norwegian && norwegian.length > 0)
        return cleanLocalizedText(norwegian[0].text)

    let english = localizedList?.filter((localized: LocalizedText) => localized.languageID === "ENG")
    if (english && english.length > 0)
        return cleanLocalizedText(english[0].text)

    return cleanLocalizedText(localizedList[0].text)
}

export interface LocalizedText {
    languageID: string
    text: string
}

export function cleanLocalizedText(text: string) {
    return text
        .replace(/[#]/g, ":")
        .replace(/[;]/g, ",")
        .replace(/[¤]/g, ";");
}

export interface RecordMetaData {
    archiveName: string[]
    pathInArchive: string[]
    schemaName: string[]
    note: string[] | null
}

export interface NutsCode {
    id: string
    label: LocalizedString | null
}

export interface NoticeDescription {
    shortContractDescription: LocalizedString | null
    economicOperatorsPersonalSituation: LocalizedString | null
    totalQuantityOrScope: LocalizedString | null
    additionalInformation: LocalizedString | null
}
